import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';

function AccessLevelGuard({component: Component, state, ...props}) {

    return (
        <Route 
            {...props} 
            render={
                props => state.admin.accessLevel !== "III" ?
                    <Redirect 
                        push={false}
                        to={{
                            pathname: "/404",
                        }}
                    /> : 
                    
                    <Component {...props}/>
            } 
        />
    )
}

AccessLevelGuard.propTypes = {
    component: PropTypes.any.isRequired,
    state: PropTypes.any.isRequired,
}

export default AccessLevelGuard;