import { gql } from '@apollo/client';


const CUSTOMERS = gql`
    query GetCustomers {
        getCustomers {
            id
            firstname
            lastname
            phonenumber
            email
            profileImage
            walletBalance
            creditMe
            debtCanIncur
            notificationToken
            progress
            totalAmountSpent
            walletBalanceBefore
            deleted
            blocked
            address
            createdAt
            lastLogin
        }
    }
`;

const CUSTOMER = gql`
    query GetCustomer($customerId: ID!) {
        getCustomer(customerId: $customerId) {
            id
            firstname
            lastname
            phonenumber
            email
            profileImage
            walletBalance
            creditMe
            debtCanIncur
            notificationToken
            progress
            totalAmountSpent
            walletBalanceBefore
            deleted
            blocked
            address
            createdAt
            lastLogin
            meternumbers {
                id
                meternumber
                address
                lastActivity
                distributor
                isPrepaid
            }
            transactions {
                id
                status
                category
                distributor
                recieptNo
                name
                meternumber
                address
                date
                amount
                phonenumber
                network
                token
                isPostpaid
                reference
                vat
                tariff
                units
                email
                outstanding
                accountNumber
            }
        }
    }
`;


export {
    CUSTOMER,
    CUSTOMERS
}