import { gql } from "@apollo/client";

const USER = gql`
    query User {
        user {
            id
            accessLevel
            name
            email
            phonenumber
        }
    }
`;

export { USER }