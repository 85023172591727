import './App.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

import React from 'react';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, Switch, Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AnimatePresence } from "framer-motion";
import Loadable from 'react-loadable';
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import { useStyles, THEME } from './shared/theme/theme';
import LinearProgress from '@material-ui/core/LinearProgress';

import AuthGuard from './guards/auth/auth.guard';
import asynComponent from './Async';
import BuyAirtimeComponent from './components/buy_airtime/buy_airtime.component';

const GlobalStyles = ()=>{
	useStyles();
	return null;
}

const useAppStyles = makeStyles((theme) => ({
	root: {
	  backgroundColor: theme.palette.background.dark,
	  display: 'flex',
	  height: '100%',
	//   overflow: 'hidden',
	  width: '100%'
	},
	wrapper: {
	  display: 'flex',
	  flex: '1 1 auto',
	//   overflow: 'hidden',
	  paddingTop: 64,
	  [theme.breakpoints.up('lg')]: {
		paddingLeft: 256
	  }
	},
	contentContainer: {
	  display: 'flex',
	  flex: '1 1 auto',
	//   overflow: 'hidden'
	},
	content: {
	  flex: '1 1 auto',
	  height: '100%',
	//   overflow: 'auto'
	}
}));

const NavbarComponent = asynComponent(()=> import('./shared/components/navbar/navbar.component').then(module => module.default));
// const AdministrativeComponent = asynComponent(()=> import('./shared/components/administrative/adminstrative.component').then(module => module.default));
const DashboardComponent = Loadable({
	loader: () => import("./components/dashboard/dashboard.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
const MakePaymentComponent = Loadable({
	loader: () => import("./components/make_payment/make_payment.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
const TransactionsComponent = Loadable({
	loader: () => import("./components/dashboard/transactions/transactions.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
// const AdminTransactionComponent = Loadable({
// 	loader: () => import("./components/dashboard/admin_transactions/admin_transactions.component"),
// 	loading() {
// 		return <LinearProgress />
// 	},
// 	delay: 500
// });
const CustomersComponent = Loadable({
	loader: () => import("./components/dashboard/customers/customers.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
const AdminsComponent = Loadable({
	loader: () => import("./components/dashboard/admins/admins.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
const AccountComponent = Loadable({
	loader: () => import("./components/dashboard/account/account.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
const CustomersChildComponent = Loadable({
	loader: () => import("./components/dashboard/customers/customers.child.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
const AdminsChildComponent = Loadable({
	loader: () => import("./components/dashboard/admins/admins.child.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
const TransactionsChildComponent = Loadable({
	loader: () => import("./components/dashboard/transactions/transactions.child.component"),
	loading() {
		return <LinearProgress />
	},
	delay: 500
});
// const AdminTransactionsChildComponent = Loadable({
// 	loader: () => import("./components/dashboard/admin_transactions/admin_transactions.child.component"),
// 	loading() {
// 		return <LinearProgress />
// 	},
// 	delay: 500
// });
const AuthComponent = Loadable({
	loader: () => import("./components/auth/auth.component"),
	loading() {
	  return <LinearProgress />
	},
	delay: 500
});
const NotfoundComponent = Loadable({
	loader: () => import("./components/notfound/notfound.component"),
	loading() {
	  return <LinearProgress />
	},
	delay: 500
});
const MailComponent = Loadable({
	loader: () => import("./components/mail/mail.component"),
	loading() {
	  return <LinearProgress />
	},
	delay: 500
});
const MessagingComponent = Loadable({
	loader: () => import("./components/messaging/messaging.component"),
	loading() {
	  return <LinearProgress />
	},
	delay: 500
});
const SettingsComponent = Loadable({
	loader: () => import("./components/settings/settings.component"),
	loading() {
	  return <LinearProgress />
	},
	delay: 500
});

function App() {
	const [isMobileNavOpen, setMobileNavOpen] = React.useState(true);

	const state = useSelector(state => state.adminState);

	const location = useLocation();	

	const classes = useAppStyles();

	return (
		<>
			<ThemeProvider theme={THEME}>
				<GlobalStyles />
				<div className={classes.root}>
					{
						location.pathname.toString().indexOf("login") === -1 ? 
							<NavbarComponent 
								onMobileClose={() => setMobileNavOpen(false)}
								openMobile={isMobileNavOpen}
								onMobileNavOpen={() => setMobileNavOpen(true)}
							/> : ''
					}
					<div className={classes.wrapper}>
						<div className={classes.contentContainer}>
							<div className={classes.content}>
								{/* <PerfectScrollbar> */}
									<AnimatePresence>
										<Switch>
											<Redirect 
												exact 
												from="/" 
												to={{
													pathname:"/dashboard"
												}}
											/>
											<AuthGuard path="/dashboard" component={DashboardComponent} state={state} isLoading={false} />
											<AuthGuard exact path="/transactions" component={TransactionsComponent} state={state} isLoading={false} />
											{/* <AuthGuard exact path="/admin_transactions" component={AdminTransactionComponent} state={state} isLoading={false} /> */}
											<AuthGuard exact path="/customers" component={CustomersComponent} state={state} isLoading={false} />
											<AuthGuard exact path="/admins" component={AdminsComponent} state={state} isLoading={false} />
											<AuthGuard path="/admins/:id" component={AdminsChildComponent} state={state} isLoading={false} />
											<AuthGuard path="/customers/:id" component={CustomersChildComponent} state={state} isLoading={false} />
											<AuthGuard path="/transactions/:id" component={TransactionsChildComponent} state={state} isLoading={false} />
											{/* <AuthGuard path="/admin_transactions/:id" component={AdminTransactionsChildComponent} state={state} isLoading={false} /> */}
											<AuthGuard path="/account" component={AccountComponent} state={state} isLoading={false}/>
											<AuthGuard path="/admin-payment" component={MakePaymentComponent} state={state} isLoading={false}/>
											<AuthGuard path="/buy-airtime" component={BuyAirtimeComponent} state={state} isLoading={false}/>
											<AuthGuard path="/mail" component={MailComponent} state={state} isLoading={false}/>
											<AuthGuard path="/messaging" component={MessagingComponent} state={state} isLoading={false}/>
											<AuthGuard path="/settings" component={SettingsComponent} state={state} isLoading={false}/>
											<Route exact path="/login" component={AuthComponent} />
											<Route path="**" component={NotfoundComponent} />
										</Switch>
									</AnimatePresence>
									{/* {
										location.pathname.toString().indexOf("login") === -1 ? 
											<AdministrativeComponent /> : ''
									} */}
								{/* </PerfectScrollbar> */}
							</div>
						</div>
					</div>
				</div>
				
			</ThemeProvider>
		</>
	);
}

export default App;
