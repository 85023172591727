import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const PageComponent = React.forwardRef(({
    children,
    title = '',
    ...rest
}, ref)=>{
    return (
        <div
          ref={ref}
          {...rest}
        >
          <Helmet>
            <title>{title}</title>
          </Helmet>
          {children}
        </div>
    );
});

PageComponent.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default PageComponent;
