export const ADD_METER_INFORMATION = 'ADD_METER_INFORMATION';

export const ADD_TRANSACTION_INFORMATION = 'ADD_TRANSACTION_INFORMATION';

export const STEP = 'STEP';

export const CLEAR = 'CLEAR';

export const ADD_METER_INFORMATION_LOADING = 'ADD_METER_INFORMATION_LOADING';

export const ERROR_MESSAGE = 'ERROR_MESSAGE';