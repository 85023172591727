import * as actionTypes from "./actionTypes";

const adminReducer = (state = {isLoggedIn: false, isLoading: true, admin: null}, action)=>{
    switch (action.type) {
        case actionTypes.LOADING:
            return {
                isLoading: true,
                ...state
            };
        case actionTypes.LOGGED_IN:
            return {
                isLoading: false,
                isLoggedIn: true,
                admin: {...action.payload}
            }
        case actionTypes.LOGGED_OUT:
            return {
                isLoading: false,
                isLoggedIn: false,
                admin: null
            };
        case actionTypes.UPDATE:
            return {
                ...state,
                isLoading: false,
                admin: {
                    ...state.admin,
                    name: action.payload.name ?? state.admin.name,
                    email: action.payload.email ?? state.admin.email,
                    phonenumber: action.payload.phonenumber ?? state.admin.phonenumber
                }
            }
        default:
            return state;
    }
}


export default adminReducer;