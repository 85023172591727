import { createStore, combineReducers } from "redux";
import adminReducer from "./admin_state/reducer";
import customersReducer from "./customers_state/reducer";
import adminsReducer from "./admins_state/reducer";
import cartReducer from "./cart_state/reducer";
import transactionsReducer from "./transactions_state/reducer";

const reducers = combineReducers({
    adminState: adminReducer, 
    customersState: customersReducer,
    adminsState: adminsReducer,
    cartState: cartReducer,
    transactionsState: transactionsReducer
});

const STORE = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default STORE;


