import * as actionTypes from "./actionTypes";

const adminsReducer = (state = {isLoading: true, admins: []}, action)=>{
    switch (action.type) {
        case actionTypes.GET_ADMINS:
            return {
                isLoading: false,
                admins: [...action.payload]
            };
        default:
            return state;
    }
}


export default adminsReducer;