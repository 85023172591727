import * as actionTypes from "./actionTypes";

export const logged_in = ({id = null, name, accessLevel, email, phonenumber})=>{
    return {
        type: actionTypes.LOGGED_IN,
        payload: {
            id,
            name,
            accessLevel,
            email,
            phonenumber
        }
    }
}

export const loading = ()=>{
    return {
        type: actionTypes.LOADING,
        payload: {}
    }
}

export const logged_out = ()=>{
    return {
        type: actionTypes.LOGGED_OUT,
        payload: {
            id: '1'
        }
    }
}

export const update = ({name = null, phonenumber = null, email = null})=>{
    return {
        type: actionTypes.UPDATE,
        payload: {
            name,
            email, 
            phonenumber
        }
    }
}