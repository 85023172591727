import * as actionTypes from "./actionTypes";

const customersReducer = (state = {isLoading: true, customers: []}, action)=>{
    switch (action.type) {
        case actionTypes.GET_CUSTOMERS:
            return {
                isLoading: false,
                customers: [...action.payload]
            };
        default:
            return state;
    }
}


export default customersReducer;