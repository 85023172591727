import React from 'react';
import _ from 'lodash';

import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { ALL_TRANSACTIONS, GET_ADMINS } from '../shared/services/queries/admins';
import { CUSTOMERS } from '../shared/services/queries/customers';

import { get_admins } from '../shared/state_management/admins_state/actions';
import { get_customers } from '../shared/state_management/customers_state/actions';
import { get_transactions } from '../shared/state_management/transactions_state/actions';

export default function AllComponent(props) {
    const dispatch = useDispatch();

    const [ customers ] = useLazyQuery(CUSTOMERS, {
		onCompleted: ({ getCustomers })=>{
			dispatch(get_customers(getCustomers));
		}
	});

    const [ admins ] = useLazyQuery(GET_ADMINS, {
		onCompleted: ({ admins })=>{
			dispatch(get_admins(admins));
		}
	});

	const [ transactions ] = useLazyQuery(ALL_TRANSACTIONS, {
		onCompleted: ({ getTransactions })=>{
			let transactions = _.isEmpty(getTransactions) ? [] : _.reverse([...getTransactions]);
			dispatch(get_transactions(transactions));
		}
	})

    React.useEffect(() => {
		customers();

		transactions();

		if(props.accessLevel === "III"){
            admins();
        }

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [customers, admins]);

    return (
        <>
            {props.children}
        </>
    )
}
