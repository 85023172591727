import { gql } from '@apollo/client';

const GET_ADMINS = gql`
    query GetAdmins {
        getAdmins {
            id
            accessLevel
            name
            email
            phonenumber
        }
    }
`;

const GET_ADMIN = gql`
    query GetAdmin($adminId: ID!) {
        getAdmin(adminId: $adminId) {
            id
            accessLevel
            name
            email
            phonenumber
            transactions {
                id
                status
                category
                distributor
                recieptNo
                name
                meternumber
                address
                date
                amount
                phonenumber
                network
                token
                isPostpaid
                reference
                vat
                tariff
                units
                email
                outstanding
                accountNumber
            }
        }
    }
`;

const ADMIN = gql`
    query GetAdmin {
        get_admin{
            id,
            email,
            name,
            phonenumber,
            access_level,
            admin_transactions{
                id,
                is_postpaid,
                units,
                amount,
                name,
                token,
                tariff,
                reference,
                reciept_no,
                address,
                meter_number,
                category,
                distributor,
                date
            }
        }
    }
`;

const ALL_TRANSACTIONS = gql`
    query GetTransactions {
        getTransactions {
            id
            status
            category
            distributor
            recieptNo
            name
            meternumber
            address
            date
            amount
            phonenumber
            network
            token
            isPostpaid
            reference
            vat
            tariff
            units
            email
            outstanding
            accountNumber
            user {
                id
                phonenumber
            }
            admin {
                email
            }
        }
    }
`;

const GET_METERNUMBERS = gql`
    query GetMeternumbers {
        getMeternumbers {
            id
            meternumber
            address
            lastActivity
            distributor
            isPrepaid
        }
    }
`

const BALANCE = gql`
    query Query {
        getBalance
    }
`;

const TOTAL_AMOUNT_SPENT = gql`
    query Query {
        getTotalAmountSpent
    }
`;


export {
    ADMIN,
    GET_ADMIN,
    GET_ADMINS,
    ALL_TRANSACTIONS,
    BALANCE,
    TOTAL_AMOUNT_SPENT,
    GET_METERNUMBERS
}