import { gql } from '@apollo/client';

const BUY_AIRTIME = gql`
    mutation BuyAirtime($phonenumber: String!, $network: String!, $amount: Float!) {
        buyAirtime(phonenumber: $phonenumber, network: $network, amount: $amount) {
            success
            message
        }
    }
`

const BUY_BULK_AIRTIME = gql`
    mutation Mutation($userId: ID!, $phonenumbers: [PhonenumberInput!]) {
        buyBulkAirtime(userId: $userId, phonenumbers: $phonenumbers) {
            success
            message
        }
    }
`

export {
    BUY_AIRTIME,
    BUY_BULK_AIRTIME
}