import * as actionTypes from './actionTypes';


const cartReducer = (state={
    showErrorMessage: false,
    isLoading: false,
    step: 0, 
    meterInformation: {
        meterNumber: '',
        plan: 'prepaid',
        amount: ''
    },
    transactionInformation: {}
}, action)=>{
    switch (action.type) {
        case actionTypes.ADD_METER_INFORMATION:
            return {
                ...state,
                meterInformation: action.payload
            }
        case actionTypes.STEP:
            return {
                ...state,
                step: action.payload.nextStep
            }
        case actionTypes.ADD_METER_INFORMATION_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case actionTypes.ERROR_MESSAGE:
            return {
                ...state,
                showErrorMessage: action.payload.showErrorMessage,
                message: action.payload.message
            }
        case actionTypes.ADD_TRANSACTION_INFORMATION:
            return {
                ...state,
                transactionInformation: action.payload
            }
        case actionTypes.CLEAR:
            return {  
                showErrorMessage: false,
                isLoading: false,
                step: 0, 
                meterInformation: {
                    meterNumber: '',
                    plan: 'prepaid',
                    amount: ''
                },
                transactionInformation: {}
            }
        default:
            return state;
    }
}

export default cartReducer;