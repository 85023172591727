import React from 'react'

export default function asynComponent(getComponent) {
    class AsyncComponent extends React.Component {
        static Component = null;
        state = {
            Component: AsyncComponent.Component
        };

        componentDidMount() {
            this.mounted = true;
            if(!this.state.Component) {
                getComponent().then(Component => {
                    AsyncComponent.Component = Component;
                    if(this.mounted){
                        this.setState({ Component })
                    }
                })
            }
        }

        componentWillUnmount() {
            this.mounted = false;
        }
        
        render() {
            const { Component } = this.state
            if (Component) {
                return <Component {...this.props} />
            }
            return null
        }
    }

    return AsyncComponent;
}
