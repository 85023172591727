import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
// import { WebSocketLink } from '@apollo/client/link/ws';
import { setContext } from '@apollo/client/link/context';
// import { getMainDefinition } from '@apollo/client/utilities';

const token = localStorage.getItem("PPNG_ADMIN");

const link = createHttpLink({
    uri: 'https://api2.paypower.ng/graphql',
    // uri: 'http://localhost:4001/graphql'
});

const authLink = setContext((_, { headers })=> {
    return {
        headers: {
            ...headers,
            "admin": `Bearer ${token}`,
        }
    }
});

// const wsLink = new WebSocketLink({
//     // uri: 'wss://api.paypower.ng/graphql',
//     uri: `ws://localhost:4000/graphql`,
//     options: {
//         reconnect: true,
//         connectionParams: {
//             adminAuthToken: `Bearer ${token}` ?? null
//         }
//     },
// });

// const splitLink = split(({ query })=>{
//     const definition = getMainDefinition(query);
//     return (
//         definition.kind === 'OperationDefinition' &&
//         definition.operation === 'subscription'
//     )
// }, wsLink, authLink.concat(link))


const Client = new ApolloClient({
    link: authLink.concat(link),
    cache: new InMemoryCache()
});

export default Client;