import { createStyles, createMuiTheme, responsiveFontSizes, makeStyles } from "@material-ui/core/styles";
import { colors } from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({
	'@global': {
		'*': {
			boxSizing: 'border-box',
			margin: 0,
			padding: 0,
		},
		html: {
			'-webkit-font-smoothing': 'antialiased',
			'-moz-osx-font-smoothing': 'grayscale',
			height: '100%',
			width: '100%'
		},
		body: {
			backgroundColor: '#f4f6f8',
			height: '100%',
			width: '100%'
		},
		a: {
			textDecoration: 'none'
		},
		'#root': {
			height: '100%',
			width: '100%'
		},
		// '*::-webkit-scrollbar': {
		//   width: '0.4em'
		// },
		// '*::-webkit-scrollbar-thumb': {
		//   minHeight: "24px",
		//   borderRadius: "8px",
		//   backgroundColor: "grey"
		// },
		// '*::-webkit-scrollbar-corner': {
		//   backgroundColor: "#202022",
		// }
	}
}));


export const THEME = responsiveFontSizes(
	createMuiTheme({
		typography: {
			fontFamily: [
				'Halant',
				'Nunito Sans'
			].join(','),
			h1: {
				fontFamily: 'Halant',
				fontWeight: 500,
				fontSize: 35,
				letterSpacing: '-0.24px'
			},
			h2: {
				fontFamily: 'Halant',
				fontWeight: 500,
				fontSize: 29,
				letterSpacing: '-0.24px'
			},
			h3: {
				fontFamily: 'Halant',
				fontWeight: 500,
				fontSize: 24,
				letterSpacing: '-0.06px'
			},
			h4: {
				fontFamily: 'Halant',
				fontWeight: 500,
				fontSize: 20,
				letterSpacing: '-0.06px'
			},
			h5: {
				fontFamily: 'Halant',
				fontWeight: 500,
				fontSize: 16,
				letterSpacing: '-0.05px'
			},
			h6: {
				fontFamily: 'Halant',
				fontWeight: 500,
				fontSize: 14,
				letterSpacing: '-0.05px'
			},
			overline: {
				fontWeight: 500
			}
		},
		palette: {
			background: {
				dark: '#F4F6F8',
				default: colors.common.white,
				paper: colors.common.white
			},
			primary: {
				main: "#009750"
			},
			secondary: {
				main: "#E17F1E"
			},
			contrastThreshold: 3
		},
		zIndex: 0
	})
);