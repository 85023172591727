import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { Redirect, Route } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

import AllComponent from '../../components'
import { USER } from "../../shared/services/queries/user";
import { logged_in, logged_out } from "../../shared/state_management/admin_state/actions";
import AccessLevelGuard from '../access_level/access_level.guard';

function AuthGuard({component: Component, isLoading, state, render, ...props}) {

    const dispatch = useDispatch();

    const [ user ] = useLazyQuery(USER, {
        onCompleted: ({ user }) => {
            if(user !== null){
                dispatch(logged_in(user))
            } else {
                dispatch(logged_out())
            }
        },
        onError: ({message})=>{
            dispatch(logged_out())
        },
        fetchPolicy: 'network-only'
    });

    React.useEffect(() => {
        if(!state.isLoggedIn){
            return user();
        }
    }, [state, user]);

    return (
        <Route 
            {...props} 
            render={
                props => state.isLoggedIn ?
                    <AllComponent access_level={state.admin.accessLevel}>
                        <AccessLevelGuard component={Component} state={state} {...props}/>
                    </AllComponent> :
                
                state.isLoading ? 
                
                <Box
                    display={"flex"}
                    justifyContent={"center"}    
                >
                    <LinearProgress />
                </Box> :

                <Redirect 
                    push={false}
                    to={{
                        pathname: "/login",
                        search: `?navigateTo=${props.location.pathname === '/' ? '/dashboard': props.location.pathname}`,
                        state: {
                            referrer: props.location.pathname
                        }
                    }}
                />
            } 
        />
    )
}

AuthGuard.propTypes = {
    component: PropTypes.any.isRequired,
    state: PropTypes.any.isRequired,
    isLoading: PropTypes.bool.isRequired
}

export default AuthGuard;