import * as actionTypes from "./actionTypes";

const transactionsReducer = (state = {isLoading: true, transactions: []}, action)=>{
    switch (action.type) {
        case actionTypes.GET_TRANSACTIONS:
            return {
                isLoading: false,
                transactions: [...state.transactions,...action.payload]
            };
        default:
            return state;
    }
}


export default transactionsReducer;